html,
body {
    min-width: 100vh;
}

#authBackground{
    z-index: -1;
    height: calc(100vh - 56px);
    width: 100vw;
    position: fixed;
    left: 0;
    top: 56px;
}

.pdf_break{
    page-break-before: always;
}

.pie-answers {
    width: 50vw;
    font-size: 14px;
}
.nav-bar {
    height: 10vh;
}

.bg-tile {
    background-color: #2d2e33;
}

.text-tile {
    color: #6c7b88;
}

.tiledate {
    padding-top: 18px;
}

.tilescan {
    padding-top: 12px;
}

.tile {
    padding-left: 10px;
}


.tile {
    height: 250px;
    opacity: 0.8;
    background: #FDC830;
    background: -webkit-linear-gradient(to right, #F37335, #FDC830);
    background: linear-gradient(to right, #F37335, #FDC830);
    position: relative;
    cursor: pointer;
    transition: all 0.25s ease-out;
    overflow: hidden;
}

.tile .text {
    padding: 30px;
    height: calc(100% - 60px);
    width: 410px;
}

.tile .text, .tile h1 {
    position: absolute;
}

.tile h1 {
    top: 50%;
    bottom: 50%;
    left: 20%;
    margin: -25px 0 0 -25px;
    transition: all 0.25s ease-in-out;
    /*text-shadow: 2px 2px 10px rgba(0,0,0,0.3);*/
}

.tile p {
    /*margin:20px;*/
    margin-top: 50px;
    line-height: 25px;

    transform: translateX(-200px);
    transition-delay: 0.0s;
}

.animate-text {
    opacity: 0;
    transition: all 0.25s ease-in-out;
}

.tile:hover {
    opacity: 1;
    background: #FDC830; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F37335, #FDC830); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F37335, #FDC830); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.64);
    transform: scale(1.05);
}

.tile:hover .animate-title {
    transform: translateY(-60px);
}

.tile:hover .animate-text {
    transform: translateX(0);
    opacity: 1;
}


.card-header {
    text-align: center;
}


.scan-label {
    padding: 20px;
    background-color: #F2E9E6;
    width: 100%;
}

.scan-title {
    background-color: #CE4B28;
    color: #fff;
    padding: 40px;
    font-size: 30px;
    margin-top: 30px;
}

.scan-question-wrapper {
    width: 100%;
}

.scan-question{
    padding:20px;
    background-color: #F2E9E6;
    width: 100%;

}

.scan-ratio-label {
    float: left;
    padding: 0 1em;
    text-align: center;
}

.scan-ratio {
    padding: 20px;
    margin: 0 auto;
    display: block;
    width: 100%;
}

.scan-counter {
    display: flex;
    justify-content: center;
    background-color: #CE4B28;
    color: #ffffff;
    width: 20px;
    height: 20px;
}

#chart2 {

}

.chart2-row {
    padding: 20px 0;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: none;
}

.pagination {
    margin-bottom: 0 !important;
}

.loginBody{
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    text-align: center;
    display: flex;
    height: 90vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.wave{
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.loginTopContainer{
    width: 100vw;
    height: 100%;
    display: grid;
    grid-gap :7rem;
    padding: 0 2rem;
    justify-content: flex-end;
}

.loginTopdiv {
    position:relative;
    grid-gap: 0;
}

.extra-login-text {
    color: black;
    text-align: left;
}

@media(min-width: 900px){
    .extra-login-text {
        position: absolute;
        left: 50px;
        top: 410px;
        width: 550px;
    }
}

.loginImg{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.login-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.loginImg img{
    width: 500px;
}

.loginContainer{
    display: flex;
    align-items: center;
    text-align: center;
}
.loginForm{
    width: 360px;
}

.login-content img{
    height: 100px;
}

.login-content h2{
    margin: 15px 0;
    color: #333;
    text-transform: uppercase;
    font-size: 2.9rem;
}

.login-content .loginInput-div{
    position: relative;
    display: grid;
    grid-template-columns: 7% 93%;
    margin: 25px 0;
    padding: 5px 0;
    border-bottom: 2px solid #d9d9d9;
}

.login-content .loginInput-div.one{
    margin-top: 0;
}

.loginI{
    color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginI i{
    transition: .3s;
}

.loginInput-div > div{
    position: relative;
    height: 45px;
}

.loginInput-div > div > h5{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    font-size: 18px;
    transition: .3s;
}

.loginInput-div:before, .loginInput-div:after{
    content: '';
    position: absolute;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background-color: #FF9A40;
    transition: .4s;
}

.loginInput-div:before{
    right: 50%;
}

.loginInput-div:after{
    left: 50%;
}

.loginInput-div.focus:before, .loginInput-div.focus:after{
    width: 50%;
}

.loginInput-div.focus > div > h5{
    top: -5px;
    font-size: 15px;
}

.loginInput-div.focus > .loginI > i{
    color: #FE7B07;
}

.loginInput-div > div > input{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem 0.7rem;
    font-size: 1.2rem;
    color: #555;
    font-family: 'poppins', sans-serif;
}

.loginInput-div.pass{
    margin-bottom: 4px;
}

.loginA{
    display: block;
    text-align: right;
    text-decoration: none;
    color: #999;
    font-size: 0.9rem;
    transition: .3s;
}

.loginA:hover{
    color: #FE7B07;
    text-decoration: none;
}

.loginBtn{
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #FF9A40, #FE7B07, #FF9A40);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin: 1rem 0;
    cursor: pointer;
    transition: .5s;
}
.loginBtn:hover{
    background-position: right;
}

.loginNav {
    display: inline-flex;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    padding: 0 20px;
}

.loginNavbar-nav {
    margin-left: 40px;
}

.loginNav-item {
    color: #83818c;
    padding: 20px;
    text-decoration: none;
    transition: 0.3s;
    margin: 0 6px;
    z-index: 1;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    position: relative;
}

.loginNav-item:before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #dfe2ea;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    transition: 0.3s;
}

.loginNav-item:not(.is-active):hover:before {
    opacity: 1;
    bottom: 0;
}

.loginNav-item:not(.is-active):hover {
    color: #333;
}

.loginNav-indicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    transition: 0.4s;
    height: 5px;
    z-index: 1;
    border-radius: 8px 8px 0 0;
}
.loginValidation{
    margin-top: 0!important;
    position: relative;
    top: -80%;
}

@media screen and (max-width: 1050px){
    .loginTopContainer{
        grid-gap: 5rem;
    }
}

@media screen and (max-width: 1000px){
    .loginForm{
        width: 290px;
    }

    .login-content loginH2{
        font-size: 2.4rem;
        margin: 8px 0;
    }

    .loginImg img{
        width: 400px;
    }

}

@media screen and (max-width: 900px){

    .loginImg{
        display: none;
    }

    .loginTopContainer{
        justify-content: center;
    }

    .wave{
        display: none;
    }
}
@media screen and (max-width: 360px) {
    .loginForm{
        width: 100%;
    }
}
@media screen and (max-width: 912px) and (min-height: 1350px){
    .loginTopContainer{
        align-items: start;
    }
}

