.bk-color-card3{
    background-color: #ffffff;
    border: 10px solid #CE4B27;
    border-bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    min-width: 90%;

}
.bk-color-card4 {
    background-color: #ffffff;
    border: 10px solid #CE4B27;
    border-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-width: 90%;
    margin-bottom: 20%;


}
.grid-triple-card {
    display: grid;
    grid-template-columns: 35% 35% 35%;
}
.grid-triple-item {
    display: inline-grid;
    grid-column-gap: 20px;

}
.triple-card-name ,.triple-card-leefgebied {
    text-align: center;
}
.margin-label-ernstige {
    margin-left: 25%;
}
.btn-triple-vw {
    margin: 0 auto;
}
.buttonbigger {
    padding: 2% 8% 2% 8%;
}
.CardImage
{
    max-height: 200px;
}
/*Responsive Cards Scan */
@media screen and (max-width: 324px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 5px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 17%;
        font-size: 0.5rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.5rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 305px) and (max-width: 325px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 6px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 17%;
        font-size: 0.6rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.6rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 326px) and (max-width: 336px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 8px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 17%;
        font-size: 0.7rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.7rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 337px) and (max-width: 349px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 9px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 22%;
        font-size: 0.7rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.7rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 350px) and (max-width: 368px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 10px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 28%;
        font-size: 0.7rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.7rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 369px) and (max-width: 378px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 12px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 26%;
        font-size: 0.8rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.8rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 379px) and (max-width: 399px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 13px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 29%;
        font-size: 0.8rem;
    }
    .margin-label-mobil {
        margin-left: 1%;
        font-size: 0.8rem;
    }
}
@media screen and (min-width: 400px) and (max-width: 427px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 15px;
        margin-left: 1%;
    }
    .margin-label-ernstige {
        margin-left: 26%;
        font-size: 0.9rem;
    }
    .margin-label-mobil {
        margin-left: 3%;
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 428px) and (max-width: 448px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 15px;
        margin-left: 3%;
    }
    .margin-label-ernstige {
        margin-left: 34%;
        font-size: 0.9rem;
    }
    .margin-label-mobil {
        margin-left: 3%;
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 449px) and (max-width: 478px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 15px;
        margin-left: 4%;
    }
    .margin-label-ernstige {
        margin-left: 39%;
        font-size: 0.9rem;
    }
    .margin-label-mobil {
        margin-left: 3%;
        font-size: 0.9rem;
    }
}
@media screen and (min-width: 479px) and (max-width: 501px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 16px;
        margin-left: 5%;
    }
    .margin-label-ernstige {
        margin-left: 39%;
        font-size: 1rem;
    }
    .margin-label-mobil {
        margin-left: 3%;
        font-size: 1rem;
    }
}

@media screen and (min-width: 502px) and (max-width: 550px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 16px;
        margin-left: 6%;
    }
    .margin-label-ernstige {
        margin-left: 43%;
        font-size: 1rem;
    }
    .margin-label-mobil {
        margin-left: 3%;
        font-size: 1rem;
    }
}
@media screen and (min-width: 551px) and (max-width: 767px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 16px;
        margin-left: 7%;
    }
    .margin-label-ernstige {
        margin-left: 48%;
        font-size: 1rem;
    }
    .margin-label-mobil {
        margin-left: 5%;
        font-size: 1rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
    .grid-triple-card{
        display: inline;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 16px;
        margin-left: 8%;
    }
    .margin-label-ernstige {
        margin-left: 51%;
        font-size: 1rem;
    }
    .margin-label-mobil {
        margin-left: 5%;
        font-size: 1rem;
    }
}
@media screen and (min-width: 801px) and (max-width: 991px) {
    .grid-triple-card{
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .grid-triple-item{
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 12px;
    }
    .margin-label-ernstige {
        margin-left: 25%;
        font-size: 0.8rem;
    }
    .margin-label-mobil{
        font-size: 0.8rem;
    }
    .CardImage
    {
        max-height: 200px;
        width: 100%;
    }
}
@media screen and (min-width: 992px) and (max-width: 1000px) {
    .grid-triple-card {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .grid-triple-item {
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 16px;
        margin-left: 5%;
    }
    .margin-label-ernstige {
        margin-left: 26%;
        font-size: 1rem;
    }
    .margin-label-mobil {
        margin-left: 10%;
        font-size: 1rem;
    }
}
@media screen and (min-width: 1001px) and (max-width: 1199px) {
    .grid-triple-card {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .grid-triple-item {
        display: inline;
    }
    .scan-ratio-label {
        float: left;
        padding: 0 1em;
        text-align: center;
        font-size: 16px;
        margin-left: 5%;
    }
    .margin-label-ernstige {
        margin-left: 39%;
        font-size: 1rem;
    }
    .margin-label-mobil {
        margin-left: 4%;
        font-size: 1rem;
    }
}
