@import '~select2/dist/css/select2.min.css';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

.select2-container {
    width: 100% !important;
}

.select2-selection {
    text-align: left !important;
}
