#swipe {
    display: flex;
    align-items: center;
    flex-direction: column;

    #swipe-card {
        margin: 10px 0;
        width: 350px;

        .content {
            padding: 20px;
            border: 10px solid #CE4B27;
            transform-origin: bottom;

            img {
                height: 200px;
            }
        }

        .options {
            grid-gap: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: white;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: black;
                margin-bottom: 0;
                opacity: 0;
                transition: .5s;
            }
        }
    }

    .options {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
}

#control-list {
    display: none;

    @media (min-width: 800px) {
        grid-template-columns: 1fr 1fr;

        .card {
            width: 80%;
        }

        .end-button {
            grid-column: 1/3;
        }

        .list-true .card .options .btn::before {
            border-top: 1em solid transparent !important;
            border-right: 1em solid !important;
            border-bottom: 1em solid transparent !important;
            border-left: 0 !important;
        }

        .list-false .card .options .btn::after {
            border-top: 1em solid transparent !important;
            border-right: 0 !important;
            border-bottom: 1em solid transparent !important;
            border-left: 1em solid !important;
        }
    }

    .end-button {
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    .list-true .card .options .btn::before {
        display: inline-block;
        content: "";
        vertical-align: -0.4em;
        border-top: 0;
        border-right: 1em solid transparent;
        border-bottom: 1em solid;
        border-left: 1em solid transparent;
    }

    .list-false .card .options .btn::after {
        display: inline-block;
        content: "";
        vertical-align: -0.4em;
        margin-left: 0.3em;
        border-top: 1em solid;
        border-right: 1em solid transparent;
        border-bottom: 0;
        border-left: 1em solid transparent;
    }

    .list {
        display: flex;
        align-items: center;
        flex-direction: column;

        .card {
            margin: 10px 0;
            width: 95%;

            .content {
                padding: 20px;
                border: 10px solid #CE4B27;
                transform-origin: bottom;

                img {
                    height: 200px;
                }

                .options {
                    display: flex;
                    justify-content: center;
                    padding-top: 20px;
                }
            }
        }
    }
}
