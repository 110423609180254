.formswitchview {
    margin-top: 50px;
}
.category-single-name,.single-card-catname ,.single-card-question {
    text-align: center;
}
.single-card {
    align-content: center;
}
.align-center-radio {
 align-content: center;
}
.single-container-vw {
    width: 50%!important;
    margin: 0 auto;
}
.bk-color-card1{
    background-color: #ffffff;
    border: 10px solid #CE4B27;
    border-bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.bk-color-card2 {
    background-color: #ffffff;
    border: 10px solid #CE4B27;
    border-top: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 50px;

}
.container-pagenation .shadow-sm,.container-pagenation .hidden{
    display: none!important;
}
.btn-margin-single {
    margin-top: 50px;
    margin-bottom: 20px;
}
.margin-auto{
    margin: 0 auto;
}
