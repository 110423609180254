$degrees: (
    "45": 45,
    "90": 90,
    "180": 180,
    "360": 360,
);

@each $name, $degree in $degrees {
    .r#{$name} {
        &:before {
            display: inline-block;
            transform: rotate(#{$degree}deg);
        }

        &:after {
            display: inline-block;
            transform: rotate(#{$degree}deg);
        }
    }
}
