// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '../css/app.css';

@import 'components';
@import '~sweetalert2/src/sweetalert2.scss';

// css single
@import 'scan-single';
// css triple
@import 'scan-triple';

// swipe
@import 'swipe';




$mynewcolor: #de835c;

.btn-orange {
    @include button-variant($mynewcolor, $mynewcolor, darken($mynewcolor, 10%), lighten($mynewcolor,5%), lighten($mynewcolor, 10%), darken($mynewcolor,30%));
}

.btn-orange-outline {
    @include button-outline-variant($mynewcolor, #ffffff, lighten($mynewcolor,5%), $mynewcolor);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: $mynewcolor !important;
}

.language-form-row:first-child .col-1 {
    display: none;
}

.card .content {
    border: black 5px solid !important;
    border-radius: 10px;
}

.bk-color-card3, .bk-color-card4 {
    border-color: black !important;
    border-width: 5px !important;
}
